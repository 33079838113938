import './App.css';
import { useState, useEffect } from 'react';
import { Progress, ConfigProvider } from 'antd';
import mqtt from 'mqtt';
import { mqttConnAndSubs } from './connection';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFlag, faWaveSquare } from "@fortawesome/free-solid-svg-icons";

const getClientId = () => `mqttjs_ + ${Math.random().toString(16).substr(2, 8)}`;

const mqttConnStr = mqtt.connect("mqtt://test.mosquitto.org:1883", {
  getClientId,
  keepalive: 60,
  clean: true,
  reconnectPeriod: 300000,
  connectTimeout: 30000,
  rejectUnauthorized: false,
  username: "",
  password: "",
  protocol: "wss",
  protocolId: 'MQTT',
  port: 8081
});

function App() {
  const [plan, setPlan] = useState(1000);
  const [actual, setActual] = useState(850);
  const [mqttConn, setMqttConn] = useState(false);
  const [client, setClient] = useState(mqttConnStr);
  const [mqttTopics, setMqttTopics] = useState(["HT/20240919/Plan", "HT/20240919/Actual"]);

  let i = 0;
  useEffect(() => {
    if (i === 0) {
      mqttConnAndSubs(client, setMqttConn, mqttTopics);
    }
    if (client) {
      let isMounted = true;
      client.on('message', (topic, msg) => {
        if (isMounted) {
          if (topic === "HT/20240919/Actual") {
            let actualC = parseInt(msg?.toString());
            setActual(actualC);
          } else if (topic === "HT/20240919/Plan") {
            let planC = parseInt(msg?.toString());
            setPlan(planC);
          }
        }
      });
      return () => { isMounted = false };
    }
    i++
  }, []);

  return (
    <div
      className="h-100 w-100"
      // style={{ backgroundImage: " linear-gradient(to left, #E1F5FE , #BBDEFB)", height: "90%" }}
      style={{ backgroundImage: " linear-gradient(to left, #000 , #000)", height: "90%" }}
    >
      <h1
        className='fw-bold text-center py-2 mt-1 mb-2 text-light shadow bg-dark'
        style={{ backgroundColor: "#1A237E" }}
      >
        Production Monitoring
      </h1>
      <div className='d-flex w-100 ' style={{ height: "80%" }}>
        <div className='h-100 w-50 d-flex flex-column justify-content-around align-items-center'>
          <div
            className='bg-success rounded-8 shadow d-flex flex-column justify-content-center align-items-center px-3'
            style={{ height: "45%", width: "85%" }}
          >
            <h1 className='fw-bold text-light pt-y' style={{ fontSize: "2.8em" }}>
              <FontAwesomeIcon icon={faFlag} className='me-3 text-light' />
              Target
            </h1>
            <h1 className='fw-bold text-light pt-y' style={{ fontSize: "5.8em" }}>
              {plan}
            </h1>
            {/* <h1 className='fw-bold text-dark' style={{ fontSize: "2.5em" }}>
              <FontAwesomeIcon icon={faFlag} className='me-3 text-light' style={{ color: "#1A237E" }} />
              Plan
            </h1>
            <div
              className='fw-bold text-center bg-success py-2 text-dark h-75 rounded-5 d-flex justify-content-center align-items-center'
              style={{ fontSize: "3em", backgroundColor: "#1A237E", width: "35%" }}
            >
              {plan}
            </div> */}
          </div>
          <div
            className='bg-warning rounded-8 shadow d-flex flex-column justify-content-center align-items-center px-3'
            style={{ height: "45%", width: "85%" }}
          >
            <h1 className='fw-bold text-light pt-y' style={{ fontSize: "2.8em" }}>
              <FontAwesomeIcon icon={faWaveSquare} className='me-3 text-light' />
              Actual
            </h1>
            <h1 className='fw-bold text-light pt-y' style={{ fontSize: "5.8em" }}>
              {actual}
            </h1>
            {/* <h1 className='fw-bold' style={{ fontSize: "2.5em" }}>
              <FontAwesomeIcon icon={faWaveSquare} className='me-3 text-light' style={{ color: "#1A237E" }} />
              Actual
            </h1>
            <div
              className='fw-bold text-center py-2 text-dark bg-warning h-75 rounded-5 d-flex justify-content-center align-items-center'
              style={{ fontSize: "3em", backgroundColor: "#1A237E", width: "35%" }}
            >
              {actual}
            </div> */}
          </div>
        </div>
        <div className='h-100 w-50 pt-2 d-flex justify-content-center align-items-center'>
          <div
            className=' px-3 py-3 shadow rounded-8 h-100 d-flex flex-column justify-content-center align-items-center'
            style={{ width: "85%" }}
          >
            <p className='fw-bold text-light fs-3 w-100 text-center pb-4'>
              Machine - <span style={{ color: "#2196F3" }}>MILLING 840D</span>
            </p>
            <ConfigProvider
              theme={{
                token: {
                  colorText: "#fff"
                },
              }}
            >
              <Progress
                className='mt-n3'
                type="dashboard"
                percent={((actual / plan) * 100)?.toFixed(1)}
                strokeColor={{
                  '0%': '#F44336',
                  '100%': '#76FF03',
                }}
                // size={{width:100, height:100}}
                size={[320, 30]}
              />
            </ConfigProvider>
            <p className='fw-bold text-light fs-3 w-100 text-center pt-2'>
              Model No - <span style={{ color: 'orange' }}> ISUZU-TREX456 </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
